(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/bit-flag/assets/javascripts/bit-flag.js') >= 0) return;  svs.modules.push('/components/lb-utils/bit-flag/assets/javascripts/bit-flag.js');

'use strict';

const BitFlag = {
  parse: value => {
    if (!Number.isInteger(value)) {
      throw new Error('Wrong format, BitFlag.parse requires an integer');
    }
    return value.toString(2).split('').reverse().map(s => Number(s));
  },
  encode: value => {
    if (!Array.isArray(value)) {
      throw new Error('Wrong format, BitFlag.encode requires an array of 1 and 0');
    }
    value.every(n => {
      if (n !== 0 && n !== 1) {
        throw new Error('Wrong format, BitFlag.encode requires an array of 1 and 0');
      }
      return true;
    });
    return parseInt(Array.from(value, n => n || 0).reverse().join(''), 2);
  }
};
if (svs.isServer) {
  module.exports = BitFlag;
} else {
  setGlobal('svs.components.lbUtils.bitFlag.BitFlag', BitFlag);
}

 })(window);